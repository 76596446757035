/* eslint no-console: ["error", { allow: ["debug"] }] */
import {
  doc,
  query,
  where,
  limit,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getAuth0Instance as authInstance } from '@/plugins/auth0'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import { RevisionEventType } from '@core/revisions/revisions'
import { allowed, FEATURES } from '@/auth'
import { constructQuery, setLocale } from '@core/utils/utils'
import { getUnix } from '@/libs/date-format'
import base from '@/views/users/base'

export default {
  namespaced: true,
  state: {
    me: {},
    list: [],
    filteredList: [],
    current: {
      data: {},
      snapshot: {},
    },
  },
  getters: {
    me: state => state.me,
    getAll: state => state.list,
    getFilteredList: state => state.filteredList.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getCurrent: state => state.current,
  },
  mutations: {
    SET_ME(state, payload) {
      state.me = payload
    },
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
    UPDATE_FILTERED_LIST(state, payload) {
      state.filteredList = payload
    },
    SET_CURRENT(state, payload) {
      state.current = {
        data: payload.data,
        snapshot: payload.snapshot,
      }
    },
  },
  actions: {
    /**
     * Fetches a document by the given ID
     *
     * @param {string} id The logs document ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({ }, id) {
      const { users } = tenantCtx()
      const userRef = doc(users, id)

      return new Promise((resolve, reject) => {
        getDoc(userRef)
          .then(docSnapshot => {
            if (docSnapshot.data().status === 'DELETED') {
              reject(new Error('404'))
            }

            resolve({
              snapshot: docSnapshot,
              data: {
                ...docSnapshot.data(),
              },
            })
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the user.'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the given filter
     *
     * @param {Object} filter - The filter
     * @param {string} filter.column - The column
     * @param {string} filter.operator - The operator
     * @param {string} filter.selector - The selector
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    async fetchOneBy({ }, filter) {
      const { users } = tenantCtx()
      const q = query(users, where(filter.column, filter.operator, filter.selector), limit(1))

      return new Promise((resolve, reject) => {
        getDocs(q)
          .then(querySnapshot => {
            const [docSnapshot] = querySnapshot.docs

            if (!docSnapshot) {
              resolve()
            } else {
              resolve({
                snapshot: docSnapshot,
                data: {
                  ...docSnapshot.data(),
                },
              })
            }
          })
          .catch(error => {
            reject(error)

            console.debug(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID
     *
     * @param {string} id The current page ID
     *
     * @returns {Promise}
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)

            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches all users
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { users } = tenantCtx()

        const q = constructQuery(users, 'status', {
          ...queryOptions,
          where: [where('status', '!=', 'DELETED')],
        })

        getDocs(q)
          .then(querySnapshot => {
            const list = []

            try {
              // eslint-disable-next-line no-restricted-syntax
              for (const userRef of querySnapshot.docs) {
                list.push({
                  id: userRef.id,
                  ...userRef.data(),
                })
              }
            } catch (error) {
              reject(error)
              console.debug(error)
            }

            commit('UPDATE_LIST', list)
            resolve(list)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the users'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches on search query
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchFiltered({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { users } = tenantCtx()

        const q = constructQuery(users, 'updatedAt', {
          ...queryOptions,
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_FILTERED_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('users') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * resets the filter list
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    resetFiltered({ commit }) {
      return new Promise(resolve => {
        commit('UPDATE_FILTERED_LIST', [])
        resolve()
      })
    },

    /**
     * Creates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    invite({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.USERS_QUOTA) && base().resolveAuth0Role(authInstance().me.data?.role) !== 'plenipotent') {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your users limit'),
            variant: 'danger',
          })

          return
        }

        const { users } = tenantCtx()
        const { email } = authInstance().me.data

        // Silent roles are special tenant-nonspecific system roles
        // e.g. like `plenipotent` and `maintainer`
        const silentRoles = [
          process.env.VUE_APP_AUTH0_MAINTAINER_ROLE_ID,
          process.env.VUE_APP_AUTH0_PLENIPOTENT_ROLE_ID,
        ]

        // Extra check to make sure the user does not invite a new user using
        // silent roles using malicious workarounds
        if (!silentRoles.includes(authInstance().me.data.role)
          && silentRoles.includes(payload.role)
        ) {
          store.dispatch('notify', {
            body: i18n.t('You attempt to invite a new user using out of scope roles!'),
            variant: 'danger',
          })

          reject()
        } else {
          dispatch('fetchOneBy', { column: 'email', operator: '==', selector: payload.email })
            .then(async existingUser => {
              if (existingUser?.data !== undefined && existingUser?.data?.status !== 'DELETED') {
                store.dispatch('notify', {
                  body: i18n.t('User is already member of your organization.'),
                  variant: 'danger',
                })

                reject()
              } else {
                dispatch('create', {
                  collectionRef: users,
                  data: {
                    firstName: payload.firstName,
                    lastName: payload.lastName,
                    email: payload.email,
                    role: payload.role,
                    locale: payload.locale,
                    status: 'PENDING',
                    invitation: {
                      inviter: email,
                    },
                  },
                })
                  .then(newUserRef => {
                    store.dispatch('revisions/create', {
                      event: RevisionEventType.INVITE_USERS,
                      id: newUserRef.id,
                      newValue: payload,
                      previousValue: {},
                    })

                    store.dispatch('notify', { title: i18n.t('Great!'), body: i18n.t('The user has been invited by e-mail 🚀') })

                    resolve(newUserRef)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    },

    /**
     * Creates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({ }, payload) {
      return new Promise((resolve, reject) => {
        addDoc(payload.collectionRef, payload.data)
          .then(docRef => {
            resolve(docRef)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Updates the `current` (i.e. `users/:id/edit`, where :id is `current`) by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    updateCurrent({ state }, payload) {
      return new Promise((resolve, reject) => {
        const formData = JSON.parse(JSON.stringify(payload)) // unref?

        // Delete redundant data
        delete formData.id

        updateDoc(state.current.snapshot.ref, formData)
          .then(() => {
            const { id } = state.current.snapshot
            const previousValue = { ...state.current.data }
            const newValue = { ...payload }

            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_USERS,
              id,
              newValue,
              previousValue,
            })

            store.dispatch('notify', { body: i18n.t('User has been updated') })

            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the User'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Updates the auth user's account settings
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    updateAccountSettings({ }, payload) {
      return new Promise((resolve, reject) => {
        const newValue = JSON.parse(JSON.stringify(payload)) // unref?
        const previousValue = authInstance().me.data

        setLocale(newValue.locale ?? null)

        updateDoc(authInstance().me.snapshot.ref, newValue)
          .then(async () => {
            await store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_USERS,
              id: authInstance().me.snapshot.id,
              newValue,
              previousValue,
            })

            store.dispatch('notify', { body: i18n.t('Your Account Settings has been updated') })

            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating your Account Settings'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Deletes (softly) a user.
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    deleteUser({ state }, payload) {
      return new Promise((resolve, reject) => {
        // Make sure the user is not deleting itself by some hacky way
        if (payload.id === authInstance().me.snapshot.id) {
          reject()
        }

        updateDoc(
          state.current.snapshot.ref,
          {
            status: 'DELETED',
            deletedAt: getUnix(),
          },
        )
          .then(() => {
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
