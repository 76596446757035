import {
  watch,
  toRefs,
  reactive,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  limit, orderBy, query, startAfter,
} from 'firebase/firestore'
import {
  transform, last, isEqual, isEmpty, forEach,
} from 'lodash'
import { convertUnixToFromNow } from '@/libs/date-format'
import { v4 as uuidv4 } from 'uuid'
import { createHash } from 'crypto'
import router from '@/router'
import i18n from '@/libs/i18n'
import store from '@/store'
import { confirmModal } from '@/libs/alerts'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)

  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * Constructs a firebase query with Query options
 *
 * @param {Object} collection
 * @param {String} orderByField
 * @param {Object} queryOptions
 * @param {number} queryOptions.max
 * @param {Object} queryOptions.offset
 * @param {Array} queryOptions.orderByList
 * @param {Array} queryOptions.where
 * @param {String} queryOptions.order
 *
 * @returns {Object} query
 */
export function constructQuery(collection, orderByField, {
  orderByList = [], order = 'asc', max, offset, where = [],
} = {}) {
  if (!offset && max) {
    return query(collection, orderBy(orderByField, order), limit(max), ...where)
  } if (offset && !max) {
    return query(collection, orderBy(orderByField, order), startAfter(offset[orderByField]), ...where)
  } if (offset && max) {
    return query(collection, orderBy(orderByField, order), startAfter(offset[orderByField]), limit(max), ...where)
  }

  return query(collection, ...orderByList, orderBy(orderByField, order), ...where)
}

/**
 * Find difference between two objects
 *
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @param  {Array} PathKeys  -  keys that needs to be added to the path
 * @param  {Array} ignoreKeys  -  keys that needs to be ignored
 *
 * @return {object} differences
 */
export function findObjectsDifferences(newObj, origObj, PathKeys, ignoreKeys) {
  const result = []
  const path = []

  function changes(newObj, origObj) {
    transform(newObj, (accumulator, value, key) => {
      if (ignoreKeys.find(k => k === key)) return accumulator

      if (!isEqual(value, origObj[key]) && key !== 'default') {
        if (path.length < 1) path.push(key)
        let K

        if (value) {
          K = PathKeys.find(pathKey => value[pathKey])

          if (K) path.push(value[K])
        }

        if (!isEmpty(value) === isEmpty(origObj[key]) && !isObject(value)) {
          result.push({
            path: JSON.stringify(path),
            name: key,
            to: value,
            from: origObj[key],
          })
        } else if (isObject(value) && isObject(origObj[key])) {
          changes(value, origObj[key])

          if (last(path) === value[K] || path.length === 1) {
            path.pop()
          }
        } else {
          result.push({
            path: JSON.stringify(path),
            name: key,
            to: value,
            from: origObj[key],
          })
        }
      }
    })
  }

  changes(newObj, origObj)

  return result
}

/**
 * Sets page url with or without preview code depedning on published state.
 *
 * @param {string} domain - Domain to use.
 * @param {Object} page - Page information.
 *
 * @return {string}
 */
export const setPageUrl = (domain, page) => {
  let url = `https://${domain}/${page.slug}`

  if (!page.published) {
    url += `?preview=${page.id}`
  }

  return url
}

/**
 * Compares two sets of data and triggers a Vuex store mutation if they are not equal.
 *
 * @param {Object} newData - The new data to compare.
 * @param {Object} oldData - The old data to compare.
 */
export const checkAndSetDataChanged = (newData, oldData) => {
  const dataEqual = isEqual(newData, oldData)

  store.commit('app/DATA_CHANGED', !dataEqual)
}

/**
 * Shows a confirmation modal for leaving a page with unsaved changes.
 *
 * @returns {Promise<boolean>} A promise that resolves to true if the user confirms leaving the page.
 */
export const confirmUnsavedLeave = async () => {
  const confirmLeaveModal = await confirmModal({
    title: i18n.t('You have unsaved changes'),
    text: i18n.t('Are you sure you want to leave this page without saving?'),
    icon: 'warning',
    confirmButtonText: i18n.t('Yes, discard changes'),
    cancelButtonText: i18n.t('Cancel'),
  })

  return confirmLeaveModal
}

/**
 * Set the locale/language of the application depending on user preference.
 * If this is not set, Use the browser language instead.
 *
 * @param {string} locale - ISO 639-1 language code
 *
 * @returns {void}
 */
export const setLocale = locale => {
  i18n.locale = locale ?? (navigator.language || navigator.userLanguage)
}

/**
 * Get the tenant domain if it's empty return Novti's default domain
 *
 * @param {string} type Domain type i.e. base
 *
 * @returns {string} domain
 */
export const resolveTenantDomain = type => {
  const tenantDomains = store.getters['domainMappings/getAll']

  // Lets search for the tenant mapped domain, if not found return Novti's default domain
  const { domain } = tenantDomains.find(mapped => mapped.status && mapped.status === 'MAPPED' && mapped.type === type) ||
    tenantDomains.find(mapped => mapped.type === type && mapped.system)

  return domain
}

/**
 * Checks if the given value is null or undefined.
 *
 * @param {*} val
 *
 * @returns {boolean}
 */
export const isNullOrUndefined = val => val === undefined || val === null

/**
 * Generates or fetches a profile image through Gravatar.
 *
 * @param {string} email User Email
 * @param {number} size Size of the avatar.
 *
 * @returns string
 */
export const generateProfileImageFromGravatar = (email, size = 50) => {
  const hash = createHash('sha256').update(email).digest('hex')

  return `https://gravatar.com/avatar/${hash}?d=mp&s=${size}`
}

/**
 * Resolves the 'time ago' epoch
 *
 * @param {number} timestamp - The timestamp used to calculate the 'time ago' from 'now'.
 *
 * @returns {string} - The 'time ago'
 */
export const resolveTimeAgo = timestamp => convertUnixToFromNow(timestamp)

/**
 * Creates a tree structure of the given files
 *
 * @param {array} fileList - The files
 * @param {array} fileTypes - The allowed file types
 *
 * @returns {array} - The tree structure
 */
export const createFilesTree = (fileList, fileTypes) => {
  const tree = {}

  if (fileList && fileList.length > 0) {
    fileList.forEach(file => {
      if (!fileTypes.includes(file.type)) {
        return
      }

      if (file.path === '') {
        tree.children.push(file)

        return
      }

      const pathSegments = file.path.split('/')
      let currentNode = tree

      pathSegments.forEach((segment, index) => {
        if (!currentNode.children) {
          currentNode.children = []
        }

        const existingNodeIndex = currentNode.children.findIndex(node => node.name === segment)

        if (existingNodeIndex === -1) {
          // Create a new node
          const newNode = {
            id: uuidv4(), // Assign a random ID for traversal purposes
            children: [],
            name: segment,
          }

          if (index === pathSegments.length - 1) {
            // Last segment, add the file
            newNode.children.push(file)
          }

          // Push to the beginning
          currentNode.children.unshift(newNode)

          currentNode = newNode
        } else {
          currentNode = currentNode.children[existingNodeIndex]

          if (index === pathSegments.length - 1) {
            // Last segment, add the file
            currentNode.children.push(file)
          }
        }
      })
    })
  }

  return tree.children || []
}

/**
 * Finds and deletes a node from the tree by the given node ID
 *
 * @param {array} tree - The tree
 * @param {string} id  - The node ID
 *
 * @returns {boolean} - True on success, false on fail
 */
export const deleteNodeById = (tree, id) => {
  for (let i = 0; i < tree.length; i += 1) {
    if (tree[i].id === id) {
      tree.splice(i, 1)

      return true
    }

    if (tree[i].children && tree[i].children.length) {
      if (deleteNodeById(tree[i].children, id)) {
        return true
      }
    }
  }

  return false
}

/**
 * Finds a node by the given ID and updates the value of the given field.
 *
 * @param {array} tree - The tree
 * @param {string} id  - The node ID
 *
 * @returns {boolean} - True on success, false on fail
 */
export const updateNodeValue = (tree, id, key, value) => {
  for (let i = 0; i < tree.length; i += 1) {
    if (tree[i].id === id) {
      tree[i][key] = value

      return true
    }

    if (tree[i].children && tree[i].children.length) {
      if (updateNodeValue(tree[i].children, id)) {
        return true
      }
    }
  }

  return false
}

/**
 * Finds a node by the given ID and returns its path.
 *
 * @param {array} tree - The tree
 * @param {string} id  - The node ID
 * @param {string} currentPath  - The current node path
 *
 * @returns {string} - The node path
 */
export const nodePathById = (tree, id, currentPath = '') => {
  for (const node of tree) {
    if (node.id === id) {
      if (!currentPath) {
        return node.name
      }

      return `${currentPath}/${node.name}`
    }

    if (node.children) {
      const parentPath = nodePathById(node.children, id, currentPath ? `${currentPath}/${node.name}` : node.name)

      if (parentPath !== null) {
        return parentPath // Found the node in the children, return the parent path
      }
    }
  }

  return null // Node not found
}

/**
 * Finds a node by the given path and returns its ID.
 *
 * @param {array} tree - The tree
 * @param {string} path  - The node path
 * @param {string} currentPath  - The current node path
 *
 * @returns {string|null} - The node ID or null if not found
 */
export const nodeIdByPath = (tree, path, currentPath = '') => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of tree) {
    if (
      (!currentPath && node.name === path)
      || `${currentPath}/${node.name}` === path
      || `${node?.path}/${node?.name}.${node?.type}` === path
    ) {
      return node.id
    }

    if (node.children) {
      const id = nodeIdByPath(node.children, path, currentPath ? `${currentPath}/${node.name}` : node.name)

      if (id !== null) {
        return id
      }
    }
  }

  return null // Node not found
}

/**
 * The `readBlob` function reads the content of a Blob object as text asynchronously.
 *
 * @param {Object} blob - The `blob` parameter in the `readBlob` function is a Blob object representing raw
 * data, typically a file. This function reads the content of the Blob as text using a FileReader and
 * returns a Promise that resolves with the text content of the Blob once it has been read.
 *
 * @returns {Promise<string>} A Promise that resolves with the result of reading the blob as text.
 */
export const readBlob = async blob => new Promise((resolve, reject) => {
  const reader = new FileReader()

  reader.onload = event => resolve(event.target.result)
  reader.onerror = error => reject(error)

  reader.readAsText(blob)
})

/**
 * Retrieves the External Resources of the current Theme.
 *
 * @NOTE - Since this function uses store getters to get the current state of the current Theme,
 * it is required to dispatch the depending data fetchers before calling it.
 *
 * @returns {Object} The current Theme's External Resources
 */
export const retrieveThemeExternalResources = (theme, themeStyles, themeScripts) => {
  const styles = []
  const scripts = []

  // @NOTE: Eventually, the external scripts under 'Security' need to be loaded as well.
  if (theme) {
    forEach(themeScripts, script => {
      scripts.push(script)
    })

    forEach(themeStyles, style => {
      styles.push(style)
    })

    styles.push({
      location: 'head',
      src: `${process.env.VUE_APP_CDN_URL}/${theme.data.storage}/public/styles.bundle.css`,
    })

    scripts.push({
      location: 'body',
      src: `${process.env.VUE_APP_CDN_URL}/${theme.data.storage}/public/app.bundle.js`,
    })
  }

  return {
    styles,
    scripts,
  }
}

/**
 * Creates HTML elements of the given External Resources.
 *
 * @param {Object} externalResources - The external resources
 *
 * @returns {Object} - The external resources
 */
export const createExternalResourcesElements = externalResources => {
  const headStylesElements = []
  const headScriptsElements = []
  const bodyScriptsElements = []

  // Scripts
  forEach(externalResources.scripts, script => {
    const scriptElement = document.createElement('script')

    scriptElement.src = script.src

    forEach(script.attributes, attr => {
      scriptElement.setAttribute(attr, '')
    })

    if (script.location === 'body') {
      bodyScriptsElements.push(scriptElement.outerHTML)
    }

    if (script.location === 'head') {
      headScriptsElements.push(scriptElement.outerHTML)
    }
  })

  // Styles
  forEach(externalResources.styles, style => {
    const linkElement = document.createElement('link')

    linkElement.rel = 'stylesheet'
    linkElement.href = style.src

    forEach(style.attributes, attr => {
      linkElement.setAttribute(attr, '')
    })

    if (style.location === 'body') {
      headStylesElements.push(linkElement.outerHTML)
    }

    if (style.location === 'head') {
      headStylesElements.push(linkElement.outerHTML)
    }
  })

  return {
    headStylesElements,
    headScriptsElements,
    bodyScriptsElements,
  }
}

/**
 * Updates the given iframe element.
 *
 * @param {string} value - The body value to inject into the iFrame
 * @param {Object} targetRef - The target iFrame reference
 * @param {Object} externalResources - The external resources to inject into the iFrame
 *
 * @returns {Object} - The iFrame's window.document value
 */
export const updateIframeElement = (value, targetRef, externalResources) => {
  const iframe = targetRef
  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
  const externalResourcesElements = createExternalResourcesElements(externalResources)

  iframeDoc.open()

  iframeDoc.write(`
    <html>
      <head>
        ${externalResourcesElements.headStylesElements.join('')}
        ${externalResourcesElements.headScriptsElements.join('')}
      </head>
      <body>
      ${value}
      ${externalResourcesElements.bodyScriptsElements.join('')}
      </body>
    </html>
  `)

  iframeDoc.close()

  return iframeDoc
}

/**
 * Reads the content of the given UI Component.
 *
 * @param {Object} uiComponent - The payload used to fetch the content
 *
 * @returns {Object} - The UI Component object
 */
export const readUIComponentContent = async uiComponent => {
  const componentBlob = await store.dispatch('themes/fetchActiveThemeFile', uiComponent)

  return readBlob(componentBlob)
}

/**
 * Reads the content of the given Element.
 *
 * @param {string} elementName - The Element name
 *
 * @returns {string} - The Element content
 */
export const readElementContent = async elementName => {
  const elementBlob = await store.dispatch('elements/downloadElementContent', {
    type: 'ejs',
    name: elementName,
  })

  return readBlob(elementBlob)
}
